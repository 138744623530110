body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: login-font;
  src: url(assets/Orbitron/orbitron-black.otf);
}

@font-face {
  font-family: login-subtitle-font;
  src: url(assets/Orbitron/orbitron-light.otf);
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.large-container{
  position: relative;
  margin-top: 10%;

}
.small-container{
  vertical-align: middle;
  display: inline-block;
  vertical-align: middle;
}

.login_card {
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 50px 100px 25px rgba(107, 113, 160, 0.3);
  border-radius: 10px;
}

.align-inner-content {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.login-img{
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: inline-block;
  border-radius: 10px;
}

.login-text{
    color: #6B71A0;
    font-family: login-font;
}

.login-left-card{
  padding: 1vw;
}

.login-title {
  font-size: 4.5vw;
  padding-bottom: .5vw;
  padding-top: 1vw;
}

.login-sessionize {
  font-size: 2vw;
  padding-bottom: 1vw;
}

.login-subtitle {
  font-family: login-subtitle-font;
  font-size: 1vw;
  padding-bottom: 2vw;
}



